import { Component } from '@angular/core';
import { LocalStorageService } from './services/local-storage.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public language = this.localStorageService.getData('language');
  public appPages = [
    {
      title: this.language == 'true' ? 'Quien soy' : 'About me',
      url: 'about-me',
      icon: 'man',
    },
    {
      title: this.language == 'true' ? 'Portafolio' : 'Portfolio',
      url: 'portfolio',
      icon: 'book',
    },
    {
      title: this.language == 'true' ? 'Habilidades' : 'Skills',
      url: 'skills',
      icon: 'library',
    },
    {
      title: this.language == 'true' ? 'Contacto' : 'Contact',
      url: 'contact',
      icon: 'mail',
    },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private localStorageService: LocalStorageService) {}
  public navigateTo(url: string) {
    // enlace de ancla
    const element = document.querySelector('.' + url);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
